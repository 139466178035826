import { ExploreProgram } from '@citruscamps/citrus-client'
import Image from 'next/image'
import { addAlpha, getHeaderBackground } from '../../../utils/branding-utils'

interface IProps {
  program: ExploreProgram
}

export const ProgramHeader = ({ program }: IProps) => (
  <header className="w-100 position-relative" style={getHeaderBackground(program?.preferences)}>
    {program?.preferences?.checkout_background_url && (
      <Image
        src={program?.preferences?.checkout_background_url}
        alt=""
        fill={true}
        priority={true}
        sizes="100vw"
        style={{ objectFit: 'cover' }}
      />
    )}
    <div
      className="position-absolute w-100 h-100"
      style={{
        backgroundColor: program.preferences?.checkout_overlay_color
          ? `#${program.preferences?.checkout_overlay_color}`
          : undefined,
        opacity: program.preferences?.checkout_overlay_opacity || 0,
      }}
    ></div>
    <div className="container h-100">
      <div className="row h-100">
        <div className="col-sm-8 col-md-6 mt-auto mx-auto mb-2 mb-sm-3 text-center">
          {program?.preferences?.logo ? (
            <div className="mt-5 mb-5 mb-sm-0 p-3">
              <img
                className="img-fluid rounded-xs"
                src={program?.preferences?.logo}
                alt={program?.name}
                style={{
                  transform: 'translateY(-60%)',
                  minHeight: '3.125rem',
                  minWidth: '3.125rem',
                  maxHeight: '11.25rem',
                  backgroundColor:
                    program.preferences.version === 1 ? addAlpha('#FFFFFF', 0.75) : undefined,
                }}
              />
            </div>
          ) : (
            <h1
              className="p-4 rounded shadow bg-light mt-5"
              style={{
                transform: 'translateY(-60%)',
              }}
            >
              {program?.name}
            </h1>
          )}
        </div>
      </div>
    </div>
  </header>
)
